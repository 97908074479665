import React, { FC } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { useWidgetActions } from '../../../hooks/useWidgetActions';
import { useWidgetViewModel } from '../../../hooks/useWidgetViewModel';
import { useServiceInfoLayout } from '../../../hooks/useServiceInfoLayout';
import { useVerticalAlignment } from '../../../hooks/useVerticalAlignment';
import {
  CatalogServiceDto,
  ConferencePlatform,
  ServiceType,
} from '@wix/bookings-uou-types';
import { Divider as TPADivider } from 'wix-ui-tpa';
import {
  MoreInfoButton as MoreInfoButtonComponent,
  MoreInfoButtonProps,
} from './MoreInfoButton/MoreInfoButton';
import { OnlineBadge as OnlineBadgeComponent } from './OnlineBadge/OnlineBadge';
import { TagLine } from './TagLine/TagLine';
import { Title } from './Title/Title';
import { Details } from './Details/Details';
import { ServiceListLayoutOptions } from '../../../../../../types/types';
import { classes, st } from './ServiceInfo.st.css';
import settingsParams from '../../../../settingsParams';
import {
  BookButton as BookButtonComponent,
  BookButtonProps,
} from './BookButton/BookButton';
import {
  DataHooks,
  ServiceDetailsType,
  ServiceInfoLayoutOption,
} from './consts';
import {
  generateServiceInfoDetails,
  ServiceInfoDisplayOptions,
} from '../../../../../../utils/serviceDetails/displayOptions';
import { ReferralInfo } from '../../../../../../utils/bi/consts';
import { CourseAvailability } from './CourseAvailability/CourseAvailability';

export type ServiceInfoProps = {
  service: CatalogServiceDto;
  statusInfo?: React.ReactNode;
  availability?: React.ReactNode;
};

type ServiceInfoByLayoutProps = ServiceInfoProps & {
  displayOptions: ServiceInfoDisplayOptions;
};

const ResponsiveHorizontalLayoutInfo: FC<ServiceInfoByLayoutProps> = ({
  service,
  displayOptions,
  statusInfo,
  availability,
}) => {
  const {
    tagLine,
    offeredDays,
    duration,
    price,
    isTooLateToBook,
    numberOfSpotsLeft,
  } = displayOptions;
  const { navigateToServicePage, navigateToBookPage } = useWidgetActions();
  const { isRTL } = useEnvironment();
  const settings = useSettings();
  const contentAlignment = useVerticalAlignment();

  return (
    <div
      data-id={service.id}
      className={st(classes.root, {
        infoLayout: ServiceInfoLayoutOption.HORIZONTAL,
        contentAlignment,
        isRTL,
      })}
      data-layout={ServiceInfoLayoutOption.HORIZONTAL}
      data-hook={DataHooks.ROOT}
    >
      <div className={classes.details}>
        <div className={classes.column}>
          <Title
            title={service.info.name}
            url={service.fullUrl}
            onClick={() =>
              navigateToServicePage(service, ReferralInfo.SERVICE_TITLE)
            }
          />
          <OnlineBadge
            isOfferedOnline={
              service.schedulePolicy?.conferenceProvider?.platform
            }
          />
          {tagLine ? <TagLine tagLine={tagLine} /> : null}
          <MoreInfoButton
            onClick={() =>
              navigateToServicePage(service, ReferralInfo.MORE_INFO_BUTTON)
            }
          />
        </div>
        <div className={classes.column}>
          {offeredDays ? (
            <OfferedDays offeredDays={offeredDays} ellipsis />
          ) : null}
          {statusInfo}
          {duration ? <Duration duration={duration} /> : null}
          {price?.displayedPrice ? (
            <Price
              displayedPrice={price.displayedPrice}
              srOnlyPrice={price.srOnlyPrice!}
            />
          ) : null}
          {availability}
        </div>
      </div>
      {settings.get(settingsParams.isBookButtonVisible) ? (
        <div className={classes.button}>
          <BookButton
            onClick={() =>
              navigateToBookPage(service, ReferralInfo.BOOK_BUTTON)
            }
            isNoBookFlow={service.schedulePolicy.displayOnlyNoBookFlow}
            isPendingApprovalFlow={service.schedulePolicy.isPendingApprovalFlow}
            showViewCourse={isTooLateToBook || numberOfSpotsLeft! <= 0}
          />
        </div>
      ) : null}
    </div>
  );
};

const VerticalLayoutInfo: FC<ServiceInfoByLayoutProps> = ({
  service,
  displayOptions,
  statusInfo,
  availability,
}) => {
  const {
    tagLine,
    offeredDays,
    duration,
    price,
    isTooLateToBook,
    numberOfSpotsLeft,
  } = displayOptions;
  const { navigateToServicePage, navigateToBookPage } = useWidgetActions();
  const { serviceListLayout } = useWidgetViewModel();
  const contentAlignment = useVerticalAlignment();
  const { isMobile, isRTL } = useEnvironment();
  const settings = useSettings();
  const { t } = useTranslation();

  return (
    <div
      data-id={service.id}
      className={st(classes.root, {
        infoLayout: ServiceInfoLayoutOption.VERTICAL,
        alignment: settings.get(settingsParams.textAlignment),
        stickButtonToBottom:
          serviceListLayout === ServiceListLayoutOptions.GRID,
        contentAlignment,
        isMobile,
        isRTL,
      })}
      data-layout={ServiceInfoLayoutOption.VERTICAL}
      data-hook={DataHooks.ROOT}
    >
      <div>
        <Title
          title={service.info.name}
          url={service.fullUrl}
          onClick={() =>
            navigateToServicePage(service, ReferralInfo.SERVICE_TITLE)
          }
        />
        <OnlineBadge
          isOfferedOnline={service.schedulePolicy?.conferenceProvider?.platform}
        />
        {tagLine ? <TagLine tagLine={tagLine} /> : null}
        <MoreInfoButton
          onClick={() =>
            navigateToServicePage(service, ReferralInfo.MORE_INFO_BUTTON)
          }
        />
        <Divider />
        <section aria-labelledby="service-info-aria-section-title" role="group">
          <span
            style={{ display: 'none' }}
            id="service-info-aria-section-title"
          >
            {t('offering.info.aria-title')}
          </span>
          {offeredDays ? <OfferedDays offeredDays={offeredDays} /> : null}
          {statusInfo}
          {duration ? <Duration duration={duration} /> : null}
          {price?.displayedPrice ? (
            <Price
              displayedPrice={price.displayedPrice}
              srOnlyPrice={price.srOnlyPrice!}
            />
          ) : null}
        </section>
      </div>
      {availability}
      <BookButton
        onClick={() => navigateToBookPage(service, ReferralInfo.BOOK_BUTTON)}
        isNoBookFlow={service.schedulePolicy.displayOnlyNoBookFlow}
        isPendingApprovalFlow={service.schedulePolicy.isPendingApprovalFlow}
        showViewCourse={isTooLateToBook || numberOfSpotsLeft! <= 0}
      />
    </div>
  );
};

export const ServiceInfo: FC<ServiceInfoProps> = ({ service }) => {
  const serviceInfoLayout = useServiceInfoLayout();
  const { businessInfo, coursesAvailability } = useWidgetViewModel();
  const { language } = useEnvironment();
  const settings = useSettings();
  const { t } = useTranslation();

  const isTagLineVisible = settings.get(settingsParams.isTagLineVisible);
  const isServiceOfferedDaysVisible = settings.get(
    settingsParams.isServiceOfferedDaysVisible,
  );
  const isServiceStartDateVisible = settings.get(
    settingsParams.isServiceStartDateVisible,
  );
  const isServiceDurationVisible = settings.get(
    settingsParams.isServiceDurationVisible,
  );
  const isServicePriceVisible = settings.get(
    settingsParams.isServicePriceVisible,
  );

  const displayOptions = generateServiceInfoDetails({
    service,
    displayOptions: {
      isServiceOfferedDaysVisible,
      isServiceStartDateVisible,
      isServicePriceVisible,
      isServiceDurationVisible,
      isTagLineVisible,
    },
    businessInfo,
    language,
    t,
    coursesAvailability,
  });

  const isHorizontal = serviceInfoLayout === ServiceInfoLayoutOption.HORIZONTAL;

  const { isTooLateToBook, numberOfSpotsLeft, startDate, isPassedEndDate } =
    displayOptions;
  const isCourseType = service.type === ServiceType.COURSE;
  const availability = isCourseType ? (
    <CourseAvailability
      numberOfSpotsLeft={numberOfSpotsLeft}
      checkDivider={!isHorizontal}
      isTooLateToBook={!!isTooLateToBook}
      isPassedEndDate={!!isPassedEndDate}
      className={!isHorizontal ? classes.CourseAvailability : undefined}
    />
  ) : null;

  const courseStatus = isServiceStartDateVisible ? (
    startDate ? (
      <StartDate
        startDate={startDate}
        locale={businessInfo.language}
        ellipsis={isHorizontal}
      />
    ) : (
      <Ended isEnded={!!isPassedEndDate} />
    )
  ) : null;

  if (isHorizontal) {
    return (
      <ResponsiveHorizontalLayoutInfo
        service={service}
        displayOptions={displayOptions}
        statusInfo={courseStatus}
        availability={availability}
      />
    );
  }

  return (
    <VerticalLayoutInfo
      service={service}
      displayOptions={displayOptions}
      statusInfo={courseStatus}
      availability={availability}
    />
  );
};

const OnlineBadge = ({
  isOfferedOnline,
}: {
  isOfferedOnline?: ConferencePlatform;
}) => {
  const settings = useSettings();
  const showBadge =
    isOfferedOnline && settings.get(settingsParams.isOnlineBadgeVisible);
  return showBadge ? <OnlineBadgeComponent /> : null;
};

const MoreInfoButton: FC<MoreInfoButtonProps> = (props) => {
  const settings = useSettings();
  return settings.get(settingsParams.isMoreInfoButtonVisible) ? (
    <MoreInfoButtonComponent {...props} />
  ) : null;
};

const Divider = () => {
  const settings = useSettings();
  return settings.get(settingsParams.isServiceDividerVisible) ? (
    <TPADivider data-hook={DataHooks.DIVIDER} className={classes.divider} />
  ) : null;
};

const OfferedDays = ({
  offeredDays,
  ellipsis = false,
}: {
  offeredDays: string;
  ellipsis?: boolean;
}) => {
  return (
    <Details type={ServiceDetailsType.OFFERED_DAYS} ellipsis={ellipsis}>
      {offeredDays}
    </Details>
  );
};

const StartDate = ({
  startDate,
  locale,
  ellipsis = false,
}: {
  startDate: string;
  locale?: string;
  ellipsis?: boolean;
}) => {
  const { isSSR } = useEnvironment();

  return (
    <Details type={ServiceDetailsType.START_DATE} ellipsis={ellipsis}>
      {locale !== 'en' && isSSR ? <>&nbsp;</> : startDate}
    </Details>
  );
};

const Ended = ({ isEnded }: { isEnded: boolean }) => {
  const { t } = useTranslation();
  if (!isEnded) {
    return null;
  }
  return <Details>{t('service.schedule.course.end.date.passed')}</Details>;
};

const Duration = ({
  duration,
  ellipsis = false,
}: {
  duration: string;
  ellipsis?: boolean;
}) => {
  return (
    <Details type={ServiceDetailsType.DURATION} ellipsis={ellipsis}>
      {duration}
    </Details>
  );
};

const Price = ({
  displayedPrice,
  srOnlyPrice,
}: {
  displayedPrice: string;
  srOnlyPrice: string;
}) => {
  return (
    <>
      <div data-hook={DataHooks.SR_ONLY_PRICE} className={classes.srOnly}>
        {srOnlyPrice}
      </div>
      <Details type={ServiceDetailsType.PRICE} ariaHidden>
        {displayedPrice}
      </Details>
    </>
  );
};

const BookButton: FC<BookButtonProps> = (props) => {
  const settings = useSettings();

  return settings.get(settingsParams.isBookButtonVisible) ? (
    <BookButtonComponent {...props} />
  ) : null;
};
