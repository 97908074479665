import {
  createSettingsParam,
  createSettingsParams,
  SettingsParamType,
} from '@wix/tpa-settings';
import {
  AlignmentOptions,
  ButtonStyleOptions,
  DisplayServicesByOptions,
  DockLocationOptions,
  FilterLayoutOptions,
  FilterServicesByOptions,
  ImagePositionOptions,
  ImageResizeOptions,
  ImageShapeOptions,
  ImageSizeOptions,
  ServiceListLayoutOptions,
} from '../../types/types';
import {
  BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID,
  BOOKINGS_MAIN_PAGE_PRESET_ID,
  CLASSIC_EDITOR_X_PRESET_ID,
  CLASSIC_PRESET_ID,
  GRID_EDITOR_X_PRESET_ID,
  GRID_PRESET_ID,
  OVERLAPPING_EDITOR_X_PRESET_ID,
  OVERLAPPING_PRESET_ID,
  SINGLE_SERVICE_EDITOR_X_PRESET_ID,
  SINGLE_SERVICE_PRESET_ID,
  STRIP_EDITOR_X_PRESET_ID,
  STRIP_PRESET_ID,
} from '../../consts';
import { FilterType, SelectedResources } from '../../../legacy/types';

export enum ISettingsParamsBooleansKeys {
  flipImageAndTextRatio = 'flipImageAndTextRatio', // IMAGE_AND_TEXT_RATIO_IS_FLIPED
  isTitleVisible = 'isTitleVisible', // DISPLAY_MULTI_OFFERINGS_TITLE
  isListFilterVisible = 'isListFilterVisible', // DISPLAY_CATEGORIES
  isServiceImageVisible = 'isServiceImageVisible', // DISPLAY_IMAGE, MOBILE_DISPLAY_IMAGE
  isListFilterStretched = 'isListFilterStretched', // FIT_CATEGORY_WIDTH
  isServicesDividerVisible = 'isServicesDividerVisible', // DISPLAY_SERVICE_DIVIDER, MOBILE_SERVICE_DIVIDER
  isOnlineBadgeVisible = 'isOnlineBadgeVisible', // DISPLAY_ONLINE_INDICATION, MOBILE_DISPLAY_ONLINE_INDICATION
  isTagLineVisible = 'isTagLineVisible', // DISPLAY_TAG_LINE, MOBILE_DISPLAY_TAG_LINE
  isMoreInfoButtonVisible = 'isMoreInfoButtonVisible', // DISPLAY_MORE_INFO_LABEL, MOBILE_DISPLAY_MORE_INFO_LABEL
  isServiceDividerVisible = 'isServiceDividerVisible', // DISPLAY_DIVIDER, MOBILE_DISPLAY_DIVIDER
  isServiceOfferedDaysVisible = 'isServiceOfferedDaysVisible', // DISPLAY_DAYS_OFFERED, MOBILE_DISPLAY_DAYS_OFFERED
  isServiceStartDateVisible = 'isServiceStartDateVisible', // DISPLAY_START_DATE, MOBILE_DISPLAY_START_DATE
  isCourseAvailabilityVisible = 'isCourseAvailabilityVisible', // DISPLAY_COURSE_AVAILABILITY
  displayNumberOfSpots = 'displayNumberOfSpots', // DISPLAY_NUMBER_OF_SPOTS
  isServiceDurationVisible = 'isServiceDurationVisible', // DISPLAY_DURATION, MOBILE_DISPLAY_DURATION
  isServicePriceVisible = 'isServicePriceVisible', // DISPLAY_PRICE, MOBILE_DISPLAY_PRICE
  isBookButtonVisible = 'isBookButtonVisible', // DISPLAY_BUTTON, MOBILE_DISPLAY_BUTTON
  showAllServicesFilterOption = 'showAllServicesFilterOption', // CATEGORY_ALL_SERVICES_SHOW
}

export enum ISettingsParamsNumbersKeys {
  cardsPerRow = 'cardsPerRow', // LAYOUT_CARDS_PER_ROW
  cardSpacing = 'cardSpacing', // SPACE_BETWEEN_OFFERINGS
  gridCardsSpacing = 'gridCardsSpacing', // CARDS_SPACING, MOBILE_CARDS_SPACING
  servicesDividerWidth = 'servicesDividerWidth', // SERVICE_DIVIDER_WIDTH
  stripCardSpacing = 'stripCardSpacing', // STRIP_SPACE_BETWEEN_OFFERINGS
}

export enum ISettingsParamsTextKeys {
  allServices = 'allServices', // CATEGORY_ALL_SERVICES_TEXT
  bookButtonText = 'bookButtonText', // BOOK_FLOW_ACTION_TEXT
  viewCourseButtonText = 'viewCourseButtonText', // VIEW_COURSE_BUTTON_TEXT
  moreInfoButtonText = 'moreInfoButtonText', // MORE_INFO_LABEL_TEXT
  noBookFlowText = 'noBookFlowText', // NO_BOOK_FLOW_ACTION_TEXT
  onlineBadgeText = 'onlineBadgeText', // ONLINE_INDICATION_TEXT
  pendingApprovalText = 'pendingApprovalText', // PENDING_APPROVAL_FLOW_ACTION_TEXT
  titleText = 'titleText', // MULTI_OFFERINGS_TITLE_TEXT
  courseAvailabilityText = 'courseAvailabilityText', // COURSE_AVAILABILITY_TEXT
  courseAvailableText = 'courseAvailableText', // COURSE_AVAILABLE_TEXT
  courseNoAvailabilityText = 'courseNoAvailabilityText', // COURSE_NO_AVAILABILITY_TEXT
}

export enum ISettingsParamsCustomsKeys {
  bookButtonStyle = 'bookButtonStyle', // BUTTON_STYLE
  filterLayout = 'filterLayout', // CATEGORY_LAYOUT_OPTION
  filterServicesBy = 'filterServicesBy', // CATEGORIES_TYPE
  displayServicesBy = 'displayServicesBy', // FILTER_BY
  listFilterAlignment = 'listFilterAlignment', // CATEGORY_ALIGNMENT
  textAlignment = 'textAlignment', // TEXT_ALIGNMENT, MOBILE_TEXT_ALIGNMENT
  titleAlignment = 'titleAlignment', // MULTI_OFFERINGS_TITLE_ALIGNMENT
  serviceListLayout = 'serviceListLayout', // OFFERINGS_LIST_LAYOUT, MOBILE_OFFERING_LIST_LAYOUT
  serviceImagePosition = 'serviceImagePosition', // IMAGE_POSITION_OPTION
  serviceImageShape = 'serviceImageShape', // IMAGE_SHAPE_OPTION
  serviceImageResize = 'serviceImageResize', // IMAGE_RESIZE_OPTION, MOBILE_IMAGE_RESIZE_OPTION
  serviceImageDockLocation = 'serviceImageDockLocation', // PIN_LOCATION, MOBILE_PIN_LOCATION
}

type ISettingsParamsBooleans = {
  [key in ISettingsParamsBooleansKeys]: SettingsParamType.Boolean;
};

type ISettingsParamsNumbers = {
  [key in ISettingsParamsNumbersKeys]: SettingsParamType.Number;
};

type ISettingsParamsText = {
  [key in ISettingsParamsTextKeys]: SettingsParamType.String;
};

type ISettingsParamsCustom = {
  [key in ISettingsParamsCustomsKeys]:
    | ButtonStyleOptions
    | FilterLayoutOptions
    | FilterServicesByOptions
    | DisplayServicesByOptions
    | AlignmentOptions
    | ServiceListLayoutOptions
    | ImagePositionOptions
    | ImageShapeOptions
    | ImageResizeOptions
    | DockLocationOptions;
};

export type ISettingsParams = {
  presetId: SettingsParamType.String;
  selectedItems: SelectedResources; // SELECTED_RESOURCES
  selectedLocations: string[]; // SELECTED_LOCATIONS
  bookButtonStyle: ButtonStyleOptions; // BUTTON_STYLE
  filterLayout: FilterLayoutOptions; // CATEGORY_LAYOUT_OPTION
  listFilterAlignment: AlignmentOptions; // CATEGORY_ALIGNMENT
  textAlignment: AlignmentOptions; // TEXT_ALIGNMENT, MOBILE_TEXT_ALIGNMENT
  filterServicesBy: FilterServicesByOptions; // CATEGORIES_TYPE
  displayServicesBy: DisplayServicesByOptions; // FILTER_BY
  titleAlignment: AlignmentOptions; // MULTI_OFFERINGS_TITLE_ALIGNMENT
  serviceListLayout: ServiceListLayoutOptions; // OFFERING_LIST_LAYOUT, MOBILE_OFFERING_LIST_LAYOUT
  serviceImagePosition: ImagePositionOptions; // IMAGE_POSITION_OPTION
  serviceImageShape: ImageShapeOptions; // IMAGE_SHAPE_OPTION
  serviceImageSize: ImageSizeOptions; // IMAGE_SIZE_OPTION, MOBILE_IMAGE_SIZE_OPTION
  serviceImageResize: ImageResizeOptions; // IMAGE_RESIZE_OPTION, MOBILE_IMAGE_RESIZE_OPTION
  serviceImageDockLocation: DockLocationOptions; // PIN_LOCATION, MOBILE_PIN_LOCATION
} & ISettingsParamsBooleans &
  ISettingsParamsNumbers &
  ISettingsParamsText &
  ISettingsParamsCustom;

const presetId = createSettingsParam('presetId', {
  getDefaultValue: (): string => '',
});

export default createSettingsParams<ISettingsParams>({
  presetId: presetId as any,
  serviceListLayout: {
    inheritDesktop: false,
    getDefaultValue: ({
      isMobile,
      getSettingParamValue,
    }): ServiceListLayoutOptions => {
      if (isMobile) {
        return ServiceListLayoutOptions.GRID;
      }
      const preset = getSettingParamValue(presetId);
      switch (preset) {
        case OVERLAPPING_PRESET_ID:
        case OVERLAPPING_EDITOR_X_PRESET_ID:
          return ServiceListLayoutOptions.OVERLAPPING;
        case STRIP_PRESET_ID:
        case STRIP_EDITOR_X_PRESET_ID:
          return ServiceListLayoutOptions.STRIP;
        case GRID_PRESET_ID:
        case GRID_EDITOR_X_PRESET_ID:
        case BOOKINGS_MAIN_PAGE_PRESET_ID:
        case BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID:
          return ServiceListLayoutOptions.GRID;
        default:
          return ServiceListLayoutOptions.CLASSIC;
      }
    },
  },
  titleText: {
    type: SettingsParamType.String,
    getDefaultValue: (): string => '',
  },
  titleAlignment: {
    getDefaultValue: (): AlignmentOptions => AlignmentOptions.CENTER,
  },
  listFilterAlignment: {
    getDefaultValue: (): AlignmentOptions => AlignmentOptions.CENTER,
  },
  textAlignment: {
    getDefaultValue: ({ getSettingParamValue, isMobile }): AlignmentOptions => {
      if (isMobile) {
        return AlignmentOptions.LEFT;
      }
      const preset = getSettingParamValue(presetId);
      switch (preset) {
        case SINGLE_SERVICE_PRESET_ID:
        case SINGLE_SERVICE_EDITOR_X_PRESET_ID:
          return AlignmentOptions.CENTER;
        default:
          return AlignmentOptions.LEFT;
      }
    },
  },
  filterLayout: {
    inheritDesktop: false,
    getDefaultValue: ({ isMobile }): FilterLayoutOptions =>
      isMobile ? FilterLayoutOptions.DROPDOWN : FilterLayoutOptions.TABS,
  },
  filterServicesBy: {
    getDefaultValue: (): FilterServicesByOptions =>
      FilterServicesByOptions.CATEGORIES,
  },
  displayServicesBy: {
    getDefaultValue: (): DisplayServicesByOptions =>
      DisplayServicesByOptions.BY_SERVICES,
  },
  isListFilterStretched: {
    type: SettingsParamType.Boolean,
    getDefaultValue: (): boolean => false,
  },
  allServices: {
    type: SettingsParamType.String,
    getDefaultValue: (): string => '',
  },
  showAllServicesFilterOption: {
    type: SettingsParamType.Boolean,
    getDefaultValue: (): boolean => false,
  },
  serviceImagePosition: {
    getDefaultValue: (): ImagePositionOptions => ImagePositionOptions.LEFT,
  },
  isServiceImageVisible: {
    type: SettingsParamType.Boolean,
    getDefaultValue: ({ getSettingParamValue, isMobile }): boolean => {
      if (isMobile) {
        return true;
      }
      const preset = getSettingParamValue(presetId);
      switch (preset) {
        case STRIP_PRESET_ID:
        case STRIP_EDITOR_X_PRESET_ID:
          return false;
        default:
          return true;
      }
    },
  },
  isTitleVisible: {
    type: SettingsParamType.Boolean,
    getDefaultValue: ({ getSettingParamValue }): boolean => {
      const preset = getSettingParamValue(presetId);
      switch (preset) {
        case BOOKINGS_MAIN_PAGE_PRESET_ID:
        case BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID:
          return true;
        default:
          return false;
      }
    },
  },
  isListFilterVisible: {
    type: SettingsParamType.Boolean,
    getDefaultValue: ({ getSettingParamValue }): boolean => {
      const preset = getSettingParamValue(presetId);
      switch (preset) {
        case BOOKINGS_MAIN_PAGE_PRESET_ID:
        case BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID:
          return true;
        default:
          return false;
      }
    },
  },
  serviceImageShape: {
    getDefaultValue: ({
      isMobile,
      getSettingParamValue,
    }): ImageShapeOptions => {
      if (isMobile) {
        return ImageShapeOptions.RECTANGLE;
      }
      const preset = getSettingParamValue(presetId);
      switch (preset) {
        case STRIP_PRESET_ID:
        case STRIP_EDITOR_X_PRESET_ID:
          return ImageShapeOptions.ROUND;
        case BOOKINGS_MAIN_PAGE_PRESET_ID:
        case BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID:
          return ImageShapeOptions.RECTANGLE;
        default:
          return ImageShapeOptions.SQUARE;
      }
    },
  },
  serviceImageSize: {
    inheritDesktop: false,
    getDefaultValue: ({ isMobile }): ImageSizeOptions =>
      isMobile ? ImageSizeOptions.MEDIUM : ImageSizeOptions.SMALL,
  },
  serviceImageResize: {
    inheritDesktop: false,
    getDefaultValue: (): ImageResizeOptions => ImageResizeOptions.CROP,
  },
  serviceImageDockLocation: {
    inheritDesktop: false,
    getDefaultValue: (): DockLocationOptions => DockLocationOptions.MIDDLE,
  },
  selectedItems: {
    getDefaultValue: (): SelectedResources => ({
      filter: FilterType.ALL,
      categories: [],
      offerings: [],
    }),
  },
  selectedLocations: {
    getDefaultValue: (): string[] => [],
  },
  flipImageAndTextRatio: {
    type: SettingsParamType.Boolean,
    getDefaultValue: ({ getSettingParamValue }): boolean => {
      const preset = getSettingParamValue(presetId);
      switch (preset) {
        case SINGLE_SERVICE_PRESET_ID:
        case SINGLE_SERVICE_EDITOR_X_PRESET_ID:
        case CLASSIC_PRESET_ID:
        case CLASSIC_EDITOR_X_PRESET_ID:
          return true;
        default:
          return false;
      }
    },
  },
  isServicesDividerVisible: {
    type: SettingsParamType.Boolean,
    getDefaultValue: (): boolean => true,
  },
  servicesDividerWidth: {
    type: SettingsParamType.Number,
    getDefaultValue: (): number => 1,
  },
  cardsPerRow: {
    inheritDesktop: false,
    type: SettingsParamType.Number,
    getDefaultValue: ({ isMobile }): number => (isMobile ? 1 : 3),
  },
  cardSpacing: {
    type: SettingsParamType.Number,
    getDefaultValue: (): number => 60,
  },
  gridCardsSpacing: {
    type: SettingsParamType.Number,
    getDefaultValue: ({ isMobile }): number => (isMobile ? 20 : 32),
  },
  stripCardSpacing: {
    type: SettingsParamType.Number,
    getDefaultValue: (): number => 0,
  },
  isOnlineBadgeVisible: {
    type: SettingsParamType.Boolean,
    getDefaultValue: (): boolean => false,
  },
  onlineBadgeText: {
    type: SettingsParamType.String,
    getDefaultValue: (): string => '',
  },
  courseAvailabilityText: {
    type: SettingsParamType.String,
    getDefaultValue: (): string => '',
  },
  courseAvailableText: {
    type: SettingsParamType.String,
    getDefaultValue: (): string => '',
  },
  courseNoAvailabilityText: {
    type: SettingsParamType.String,
    getDefaultValue: (): string => '',
  },
  isTagLineVisible: {
    type: SettingsParamType.Boolean,
    getDefaultValue: ({ getSettingParamValue, isMobile }): boolean => {
      if (isMobile) {
        return true;
      }
      const preset = getSettingParamValue(presetId);
      switch (preset) {
        case STRIP_PRESET_ID:
        case STRIP_EDITOR_X_PRESET_ID:
        case GRID_PRESET_ID:
        case GRID_EDITOR_X_PRESET_ID:
        case BOOKINGS_MAIN_PAGE_PRESET_ID:
        case BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID:
        case SINGLE_SERVICE_PRESET_ID:
        case SINGLE_SERVICE_EDITOR_X_PRESET_ID:
          return false;
        default:
          return true;
      }
    },
  },
  isMoreInfoButtonVisible: {
    type: SettingsParamType.Boolean,
    getDefaultValue: (): boolean => false,
  },
  moreInfoButtonText: {
    type: SettingsParamType.String,
    getDefaultValue: (): string => '',
  },
  isServiceDividerVisible: {
    type: SettingsParamType.Boolean,
    getDefaultValue: ({ getSettingParamValue, isMobile }): boolean => {
      if (isMobile) {
        return true;
      }
      const preset = getSettingParamValue(presetId);
      switch (preset) {
        case SINGLE_SERVICE_PRESET_ID:
        case SINGLE_SERVICE_EDITOR_X_PRESET_ID:
          return false;
        default:
          return true;
      }
    },
  },
  isServiceOfferedDaysVisible: {
    type: SettingsParamType.Boolean,
    getDefaultValue: ({ getSettingParamValue, isMobile }): boolean => {
      if (isMobile) {
        return true;
      }
      const preset = getSettingParamValue(presetId);
      switch (preset) {
        case STRIP_PRESET_ID:
        case STRIP_EDITOR_X_PRESET_ID:
          return false;
        default:
          return true;
      }
    },
  },
  isServiceStartDateVisible: {
    type: SettingsParamType.Boolean,
    getDefaultValue: ({ getSettingParamValue, isMobile }): boolean => {
      if (isMobile) {
        return true;
      }
      const preset = getSettingParamValue(presetId);
      switch (preset) {
        case STRIP_PRESET_ID:
        case STRIP_EDITOR_X_PRESET_ID:
          return false;
        default:
          return true;
      }
    },
  },
  isCourseAvailabilityVisible: {
    type: SettingsParamType.Boolean,
    getDefaultValue: ({ getSettingParamValue, isMobile }): boolean => {
      return true;
    },
  },
  displayNumberOfSpots: {
    type: SettingsParamType.Boolean,
    getDefaultValue: ({ getSettingParamValue, isMobile }): boolean => {
      return false;
    },
  },
  isServiceDurationVisible: {
    type: SettingsParamType.Boolean,
    getDefaultValue: ({ getSettingParamValue, isMobile }): boolean => {
      if (isMobile) {
        return true;
      }

      const preset = getSettingParamValue(presetId);
      switch (preset) {
        case CLASSIC_PRESET_ID:
        case CLASSIC_EDITOR_X_PRESET_ID:
        case STRIP_PRESET_ID:
        case STRIP_EDITOR_X_PRESET_ID:
          return false;
        default:
          return true;
      }
    },
  },
  isServicePriceVisible: {
    type: SettingsParamType.Boolean,
    getDefaultValue: (): boolean => true,
  },
  isBookButtonVisible: {
    type: SettingsParamType.Boolean,
    getDefaultValue: (): boolean => true,
  },
  noBookFlowText: {
    type: SettingsParamType.String,
    getDefaultValue: (): string => '',
  },
  pendingApprovalText: {
    type: SettingsParamType.String,
    getDefaultValue: (): string => '',
  },
  bookButtonText: {
    type: SettingsParamType.String,
    getDefaultValue: (): string => '',
  },
  bookButtonStyle: {
    getDefaultValue: (): ButtonStyleOptions => ButtonStyleOptions.SQUARE_FILL,
  },
  viewCourseButtonText: {
    type: SettingsParamType.String,
    getDefaultValue: (): string => '',
  },
});
